<template>
<!-- 类型管理 -->
  <div class="box100">
    <div class="whiteBg">
      <div class="flexCB">
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="name"  placeholder="请输入名称" class="inputSearch"></el-input>
          <el-button  class="btnSearch"  @click="handleSearch">搜索</el-button>
          <el-button  class="btnReset"  @click="handleReset">重置</el-button>
        </div>
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </div>
      <div class="table_cont">
        <el-table
          :data="typeList"
          :header-cell-style="{background:'#F6F9FF'}">
          <el-table-column label="序号" width="80">
            <template #default="scope"><span>{{(page - 1) * size + scope.$index + 1}}</span></template>
          </el-table-column>
          <el-table-column
            label="名称"
            prop="name">
          </el-table-column>
          <el-table-column
            label="编码"
            prop="code">
          </el-table-column>
          <el-table-column
            label="关联告警名称"
            prop="alarm_count">
            <template slot-scope="scope">
              <span v-if="scope.row.alarm_count > 0"  style="color:#5473E8;cursor: pointer;" @click="handleCount(scope.row)">{{scope.row.alarm_count}}</span>
              <span v-if="scope.row.alarm_count <= 0">{{scope.row.alarm_count}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="描述"
            prop="mark">
          </el-table-column>
          <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleAlam(scope.row)">关联告警名称</el-button>
              <el-button type="text" size="small"  style="color:#FF4C4C;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="typefrom" :rules="formRules" ref="typeFrom">
        <el-form-item label="名称" prop="name">
          <el-input v-model="typefrom.name"  placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="typefrom.code"  placeholder="请输入编码" ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="mark">
          <el-input v-model="typefrom.mark" type="textarea" :rows="3" maxlength="200" placeholder="请输入描述(200字以内)"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 关联告警名称弹窗 -->
    <el-dialog title="关联告警名称" :visible.sync="typeShow" center width="700px" class="gyDialog" :close-on-click-modal="false" :before-close="typeCancel">
      <el-transfer v-model="value" :data="data" :titles="['未关联', '已关联']"></el-transfer>
      <div class="flexCE box_bottom">
        <div class="settingCancel" @click="typeCancel">取消</div>
        <div class="settingConfirm" @click="typeConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 关联告警名称数量弹窗 -->
    <el-dialog title="关联告警名称" :visible.sync="countShow" center width="450px" class="gyDialog" :close-on-click-modal="false" :before-close="countCancel">
      <div class="flexF">
        <p class="udi_r" v-for="(item,index) in this.equList" :key="index">{{item.name}}</p>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { equtypeGet, equtypeDel, equtypeAdd, equtypeUpdate, equtypeAlarm, equtypeCommit } from '@/api/port.js'
export default {
  data () {
    // const generateData = _ => {
    //   const data = [];
    //   for (let i = 1; i <= 10; i++) {
    //     data.push({
    //       key: i,
    //       label: `备选项 ${ i }`,
    //       // disabled: i % 4 === 0
    //     });
    //   }
    //   return data;
    // };
    return {
      dialogName: '新增',
      name: '',
      typeList: [],
      page: 1,
      size: 10,
      total: 0,
      fromShow: false,
      typefrom: { name: '', code: ''},
      formRules: {
        name: [
          { required: true, trigger: 'blur', message: '名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, trigger: 'blur', message: '编码不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      typeShow: false,
      data: [],
      value: [],
      correId: '',
      countShow: false,
      equList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        name: this.name,
        page_index: this.page,
        page_size: this.size
      }
      equtypeGet(params).then(res => {
        if (res.status === 1) {
          this.typeList = res.data.data
          this.total = res.data.total
        } else {
          this.typeList = []
          this.total = 0
        }
      })
    },
    // 关联告警数量展示
    handleCount (row) {
      this.countShow = true
      this.equList = row.alarm_list
    },
    // 关联告警数量取消
    countCancel () {
      this.countShow = false
    },
    // 关联告警名称 弹窗
    handleAlam (row) {
      this.typeShow = true
      this.correId = row.id
      equtypeAlarm({id: row.id}).then(res => {
        if (res.status === 1) {
          this.data = res.data.data
          this.value = res.data.value
        }
      })
    },
    // 关联告警名称 取消
    typeCancel () {
      this.typeShow = false
    },
    // 关联告警名称 确定
    typeConfirm () {
      equtypeCommit ({id: this.correId, setlist: this.value}).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.typeCancel()
          this.init()
        }else {
          this.$message.error(res.message)
        }
      })
    },
    // 新增弹窗
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.$nextTick(() => {
        this.$refs.typeFrom.resetFields()
        this.typefrom = {}
      })
    },
    // 编辑弹窗
    handleEdit (row) {
      this.fromShow = true
      this.dialogName = '编辑'
      this.typefrom = { ...row }
    },
    // 取消
    FromCancel () {
      this.$refs.typeFrom.resetFields()
      this.fromShow = false
    },
    FromConfirm () {
      this.$refs.typeFrom.validate((valid) => {
        if (!valid) return
        const params = {
          id: this.typefrom.id,
          name: this.typefrom.name,
          code: this.typefrom.code,
          mark: this.typefrom.mark
        }
        if (this.dialogName === '新增') {
          equtypeAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          equtypeUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        equtypeDel({ id: row.id }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.name = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.box_bottom {
  margin-top: 30px;
}
.udi_r {
  height: 38px;
  background: #F5F9FD;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 9px 16px;
  box-sizing: border-box;
  margin-right: 16px;
  font-size: 14px;
  color: #333333;
}
</style>
